import $ from "jquery";
import gsap from "gsap";

export default function Hover() {
    $(".menu-list").each(function () {
        $(this).removeClass("link-border");
        $(this).append("<div class='link-border'></div>");
    })
    $(".menu-list").on("mouseover", function () {
        const border = $(this).find(".link-border");
        gsap.to(border, {
            transformOrigin: "left",
            scaleX: 1,
            duration: 0.4,
            ease: "expo.out",
        })
    });
    $(".menu-list").on("mouseleave", function () {
        const border = $(this).find(".link-border");
        gsap.to(border, {
            transformOrigin: "right",
            scaleX: 0,
            duration: 0.4,
            ease: "expo.out",
        });
    });



    $(".link-icon").on("mouseenter", function () {
        const icon = $(this).find("svg");

        const tl_icon = gsap.timeline({
            defaults: {
                ease: "expo.out",
                duration: 0.4
            }
        });
        tl_icon.to(icon, {
            xPercent: 100,
        });

        tl_icon.set(icon, {
            xPercent: -100,
        });

        tl_icon.to(icon, {
            xPercent: 0,
        });

        tl_icon.to($(this), {
            scale: 1.2,
            ease: "power4.out",
            duration: 0.8
        }, 0);
    });

    $(".link-icon").on("mouseleave", function () {
        const icon = $(this).find("svg");

        const tl_icon = gsap.timeline({
            defaults: {
                ease: "expo.out",
                duration: 0.4
            }
        });
        tl_icon.to(icon, {
            xPercent: -100,
        });

        tl_icon.set(icon, {
            xPercent: 100,
        });

        tl_icon.to(icon, {
            xPercent: 0,
        });

        tl_icon.to($(this), {
            scale: 1,
            ease: "power4.out",
            duration: 0.8
        }, 0);
    });
}