import $ from "jquery";
import gsap from "gsap";

export default function customCursor() {
    const cursor = document.querySelectorAll(".cursor-masking-mask");

    const mouse = {
        x: -100,
        y: -100
    }; // mouse pointer's coordinates
    const pos = {
        x: 0,
        y: 0
    }; // cursor's coordinates
    const speed = 0.10; // between 0 and 1

    const updateCoordinates = e => {
        mouse.x = e.clientX;
        mouse.y = e.clientY;
    }

    const updateCursor = () => {
        const diffX = Math.round(mouse.x - pos.x - 700);
        const diffY = Math.round(mouse.y - pos.y - 700);

        pos.x += diffX * speed;
        pos.y += diffY * speed;

        let xSetter = gsap.utils.pipe(
            gsap.utils.snap(5),
            gsap.quickSetter(cursor, "x", "px")
        );

        let ySetter = gsap.utils.pipe(
            gsap.utils.snap(5),
            gsap.quickSetter(cursor, "y", "px")
        );

        xSetter(pos.x)
        ySetter(pos.y)


        // Quicksetter lighter but jaggy
        // let xSetter_img = gsap.utils.pipe(
        //     gsap.quickSetter(".slider-list-item-image-wrap.is-active .slider-list-item-image", "x", "px")
        // );

        // let ySetter_img = gsap.utils.pipe(
        //     gsap.quickSetter(".slider-list-item-image-wrap.is-active .slider-list-item-image", "y", "px")
        // );

                // xSetter_img(pos.x / 30)
        // ySetter_img(pos.y / 30)

        gsap.to(".slider-list-item-image-wrap.is-active .slider-list-item-image", {
            x: pos.x / 30,
            y: pos.y / 30,
            duration: 0.3,
            delay: 0.1,
            ease: "power1.out"
        });
    };

    function loop() {
        updateCursor();
        requestAnimationFrame(loop);
    }

    window.addEventListener('mousemove', updateCoordinates);
    requestAnimationFrame(loop);
}