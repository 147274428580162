import $ from "jquery";
import gsap from "gsap";

export default function Magnet() {
    $('.magnet').each(function () {
        var $self = $(this);
        var hover = false;
        var offsetHoverMax = $self.attr("offset-hover-max") || 0.7;
        var offsetHoverMin = $self.attr("offset-hover-min") || 0.5;

        var attachEventsListener = function () {
            $(window).on("mousemove", function (e) {
                //
                var hoverArea = hover ? offsetHoverMax : offsetHoverMin;

                // cursor
                var cursor = {
                    x: e.clientX,
                    y: e.clientY - $(window).scrollTop()
                };

                // size
                var width = $self.outerWidth();
                var height = $self.outerHeight();

                // position
                var offset = $self.offset();
                var elPos = {
                    x: offset.left + width / 2,
                    y: offset.top + height / 2
                };

                // comparaison
                var x = cursor.x - elPos.x;
                var y = cursor.y - elPos.y;

                // dist
                var dist = Math.sqrt(x * x + y * y);

                // mutex hover
                var mutHover = false;

                // anim
                if (dist < width * hoverArea) {
                    mutHover = true;
                    if (!hover) {
                        hover = true;
                    }
                    onHover(x, y);
                }

                // reset
                if (!mutHover && hover) {
                    onLeave();
                    hover = false;
                }
            });
        };

        var onHover = function (x, y) {
            gsap.to($self, {
                x: x * 0.8,
                y: y * 0.8,
                duration: 0.4,
                //scale: .9,
                // rotation: x * 0.05,
                ease: "bounce.out"
            });
        };
        var onLeave = function () {
            gsap.to($self, {
                x: 0,
                y: 0,
                scale: 1,
                // rotation: 0,
                duration: 0.7,
                ease: "power4.out"
            });
        };

        attachEventsListener();
    });


    // var cerchio = document.querySelectorAll('.magnet2');

    // cerchio.forEach(function (elem) {
    // 	$(document).on('mousemove touch', function (e) {
    // 		magnetize(elem, e);
    // 	});
    // })


    // function magnetize(el, e) {
    // 	var mX = e.pageX,
    // 	mY = e.pageY;
    // 	const item = $(el);

    // 	const customDist = item.data('dist') * 20 || 80;
    // 	const centerX = item.offset().left + (item.width() / 2);
    // 	const centerY = item.offset().top + (item.height() / 2);

    // 	var deltaX = Math.floor((centerX - mX)) * -0.35;
    // 	var deltaY = Math.floor((centerY - mY)) * -0.35;

    // 	var distance = calculateDistance(item, mX, mY);

    // 	if (distance < customDist) {
    // 		gsap.to(item, {
    //             duration: 0.5,
    // 			y: deltaY,
    // 			x: deltaX,
    // 			scale: 1
    // 		});
    // 		item.addClass('magnet');
    // 	} else {
    // 		gsap.to(item, {
    //             duration: 0.6,
    // 			y: 0,
    // 			x: 0,
    // 			scale: 1
    // 		});
    // 		item.removeClass('magnet');
    // 	}
    // }

    // function calculateDistance(elem, mouseX, mouseY) {
    // 	return Math.floor(Math.sqrt(Math.pow(mouseX - (elem.offset().left + (elem.width() / 2)), 2) + Math.pow(mouseY - (elem.offset().top + (elem.height() / 2)), 2)));
    // }

    // /* Mouse sticky */
    // function lerp(a, b, n) {
    // 	return (1 - n) * a + n * b
    // }

};