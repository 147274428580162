import $ from 'jquery';
import gsap from "gsap";

function Hamburger() {
    let menuopened = false;

    $(window).on('resize', function () {
        if (window.matchMedia('(max-width: 767.98px)').matches) {
            gsap.set("#navbar", {
                display: "none",
                opacity: 0
            });
        } else {
            gsap.set("#navbar", {
                display: "block",
                opacity: 1
            });
        }
    });

    $("#navbar a, #navbar-mobile a").on('click', function () {
        tl_menu.reverse();
        menuopened = false;
    });

    const tl_menu = gsap.timeline({
        paused: true,
        defaults: {
            ease: "expo.inOut",
            duration: 1
        }
    });

    tl_menu.set("#navbar *", {
        x: -200,
        stagger: 0
    });

    tl_menu.to("#hamburger .hamburger-line-1", {
        rotation: 55,
        x: 0,
        y: 0,
        duration: 1,
        ease: "power4.inOut"
    });

    tl_menu.to("#hamburger .hamburger-line-2", {
        rotation: 125,
        x: 0,
        y: -9,
        duration: 1,
        ease: "power4.inOut"
    }, 0);

    tl_menu.to(".navbar-overlay", {
        scaleX: 1,
        display: "block"
    }, 0);

    tl_menu.to("#navbar", {
        opacity: 1,
        display: "block",
        duration: 0.1,
    }, "-=1");

    tl_menu.to("#navbar *", {
        x: 0,
        stagger: 0.02
    }, "-=1.2");

    tl_menu.to("#navbar-mobile .logo-black", {
        opacity: 1,
        duration: 0.3
    }, +0.5);

    tl_menu.to("#navbar-mobile .digitalagency-mobile", {
        color: "#2b3958",
        duration: 0.3
    }, +0.5);

    tl_menu.to("#hamburger .hamburger-line", {
        backgroundColor: "#2b3958",
        duration: 0.3
    }, +0.8);

    $('#hamburger').on('click', function () {
        if (menuopened === false) {
            tl_menu.play();
            menuopened = true;
        } else {
            tl_menu.reverse();
            menuopened = false;
        }
    });
}

export default function Mobile() {
    Hamburger();
}