import imagesLoaded from "imagesloaded";
import gsap from "gsap";
import FontFaceObserver from 'fontfaceobserver';

let elLoading = document.querySelector("#loading");
let elLoadingtext = document.querySelector("#loading .loading-text");

function preloadImages(selector) {
    return new Promise((resolve, reject) => {
        imagesLoaded(selector, resolve);
    });
}

export default function Loading(_callback) {
    const font1 = new FontFaceObserver('didonesque');
    const font2 = new FontFaceObserver('opensans');

    // Element select
    let elMain = "#main";

    // Image counter
    let loadedImageCount = 0;
    let imageCount = 0;

    // Scroll activate toggle
    function scrollActivate(toggle) {
        document.body.style.overflowY = toggle;
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    }

    scrollActivate("hidden");

    const imgLoad = imagesLoaded(elMain, {
        background: '.img'
    });

    // Show progress
    function updateProgress(value) {
        const diplay = value / imageCount;
        elLoadingtext.firstChild.data = `${Math.round(diplay * 100)}`;
    }

    imageCount = imgLoad.images.length;

    imgLoad.on("progress", () => {
        loadedImageCount += 1;
        updateProgress(loadedImageCount);
    });


    // Images done loaded
    return Promise.all([font1.load(), font2.load(), preloadImages(elMain)]).then(_ => {
        Loading_done();
    
        scrollActivate("visible");

        _callback();
    });
}

function Loading_done() {
    const tl_loadcomplete = gsap.timeline();
    tl_loadcomplete.to(".loading-text, .loading-desc", {
        opacity: 0,
        x: -40,
        stagger: "0.2",
        ease: "expo.out",
        duration: 1.4,
    });

    tl_loadcomplete.to(".loading-overlay", {
        width: "140%",
        ease: "expo.inOut",
        duration: 1.4,
    }, "-=1.2");

    tl_loadcomplete.to(elLoading, {
        scaleX: 0,
        ease: "expo.inOut",
        duration: 1.4,
        display: "none",
    }, "-=1.4");
}