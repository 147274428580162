import $ from "jquery";
import gsap from "gsap";

export default function Slider() {
    try {
        let vh = window.innerHeight * 0.01;
        // Then we set the value in the --vh custom property to the root of the document
        document.documentElement.style.setProperty('--vh', `${vh}px`);

        document.getElementById('slider-video').play();

        if (!/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)) {
            $(".slider-list-item-content-firstload .line").each(function (i, el) {
                let char = $(el).find(".char");
                gsap.from(char, {
                    opacity: 0,
                    x: 180,
                    ease: "power4.inOut",
                    duration: 1.4,
                    stagger: 0.02,
                    delay: 0.3,
                    onComplete: function (self) {
                        gsap.killTweensOf(self, true);
                    }
                });
            });

            gsap.from(".slider-list-item-content-firstload .slider-list-item-content-caption-btn, .slider-pagination, .slider-arrows", {
                opacity: 0,
                x: 180,
                ease: "expo.inOut",
                duration: 1.4,
                stagger: 0,
                delay: 0.5,
                onComplete: function (self) {
                    gsap.killTweensOf(self, true);
                }
            });
        } else {
            gsap.from(".slider-list-item-content-firstload", {
                opacity: 0,
                x: 180,
                ease: "power4.inOut",
                duration: 1.4,
                stagger: 0,
                delay: 0.5,
            }, 0);
        }

        const slider = $('#slider');

        slider.find('.slider-list-item').removeClass('is-not-active');

        let isblackstate = false;

        function sliderSwitch(slider, index) {
            if (slider.data('wait')) return;

            const slides = slider.find('.slider-list-item');
            const pages = slider.find('.slider-pagination');
            const activeSlide = slides.filter('.is-active');
            const activeSlideImage = activeSlide.find('.slider-list-item-image');
            const activeSlideElements = activeSlide.find('.slider-list-item-content-caption');
            const newSlide = slides.eq(index);
            const newSlideImage = newSlide.find('.slider-list-item-image');
            const newSlideContent = newSlide.find('.slider-list-item-content');
            const newSlideElements = newSlide.find('.slider-list-item-content-caption');
            const newSlideElements_btn = newSlide.find(".slider-list-item-content-caption-btn");
            const newSlideElements_text = newSlide.find(".slider-list-item-content-caption .line");

            const isblackel = $("body, a, .slider-pagination-item, .slider-arrows-arrow, .footer-email-link-icon, .footer-socmed-list-item, .digitalagency-mobile");
            const isblackel_bg = $(".link-border, .hamburger-line");

            if (!/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)) {
                var tl_isblack = gsap.timeline({
                    paused: true,
                    delay: 1,
                    defaults: {
                        duration: 0.3,
                    }
                });

                var tl_iswhite = gsap.timeline({
                    paused: true,
                    delay: 1,
                    defaults: {
                        duration: 0.3,
                    }
                });
            } else {
                var tl_isblack = gsap.timeline({
                    paused: true,
                    delay: 0.5,
                    defaults: {
                        duration: 0.3,
                    }
                });

                var tl_iswhite = gsap.timeline({
                    paused: true,
                    delay: 0.5,
                    defaults: {
                        duration: 0.3,
                    }
                });
            }

            tl_isblack.to(isblackel, {
                color: "#13161F",
                borderColor: "#13161F",
                stagger: 0,
            });

            tl_isblack.to(isblackel_bg, {
                background: "#13161F",
                stagger: 0,
            }, 0);

            tl_isblack.to('.logo-white', {
                opacity: 0,
            }, 0);

            tl_isblack.to('.logo-black', {
                opacity: 1,
            }, 0);


            tl_iswhite.to(isblackel, {
                color: "#FFFFFF",
                borderColor: "#FFFFFF",
                stagger: 0,
            });

            tl_iswhite.to(isblackel_bg, {
                background: "#FFFFFF",
                stagger: 0,
            }, 0);

            tl_iswhite.to('.logo-white', {
                opacity: 1,
            }, 0);

            tl_iswhite.to('.logo-black', {
                opacity: 0,
            }, 0);

            if (newSlide.index() === 2) {
                if (isblackstate === false) {
                    tl_isblack.play();

                    isblackstate = true;
                }
            } else {
                if (isblackstate === true) {
                    tl_iswhite.play();

                    isblackstate = false;
                }
            }
            if (newSlide.is(activeSlide)) return;

            newSlide.addClass('is-new');
            const timeout = slider.data('timeout');
            clearTimeout(timeout);
            slider.data('wait', true);

            if (newSlide.index() > activeSlide.index()) {
                var newSlideRight = 0;
                var newSlideLeft = 'unset';
                var newSlideImageRight = -slider.width() / 8;
                var newSlideImageLeft = 'unset';
                var newSlideImageToRight = 0;
                var newSlideImageToLeft = 'unset';
                var newSlideContentLeft = 'unset';
                var newSlideContentRight = 0;
                var activeSlideImageLeft = -slider.width() / 4;
            } else {
                var newSlideRight = '';
                var newSlideLeft = 0;
                var newSlideImageRight = 'unset';
                var newSlideImageLeft = -slider.width() / 8;
                var newSlideImageToRight = '';
                var newSlideImageToLeft = 0;
                var newSlideContentLeft = 0;
                var newSlideContentRight = 'unset';
                var activeSlideImageLeft = slider.width() / 4;
            }

            gsap.set(newSlide, {
                display: 'block',
                width: 0,
                right: newSlideRight,
                left: newSlideLeft,
                zIndex: 2
            });

            gsap.set(newSlideImage, {
                width: slider.width(),
                right: newSlideImageRight,
                left: newSlideImageLeft
            });

            gsap.set(newSlideContent, {
                width: slider.width(),
                left: newSlideContentLeft,
                right: newSlideContentRight
            });

            gsap.set(activeSlideImage, {
                left: 0,
            });

            if (!/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)) {
                var tl_newslide = gsap.timeline({
                    defaults: {
                        duration: 2,
                        ease: "expo.inOut"
                    },
                });
            } else {
                var tl_newslide = gsap.timeline({
                    defaults: {
                        duration: 1.2,
                        ease: "power4.inOut"
                    },
                });
            }


            tl_newslide.set(newSlideElements, {
                opacity: 0,
                force3D: true,
            });

            tl_newslide.to(activeSlideElements, {
                opacity: 0,
                duration: 0.5,
                ease: "power1.in"
            }, 0);

            tl_newslide.to(activeSlideImage, {
                left: activeSlideImageLeft,
            }, 0);

            tl_newslide.to(newSlide, {
                width: slider.width(),
            }, 0);

            tl_newslide.to(newSlideImage, {
                right: newSlideImageToRight,
                left: newSlideImageToLeft,
                onComplete: function () {
                    gsap.set(newSlide, {
                        display: '',
                        width: '',
                        left: '',
                        zIndex: ''
                    });

                    gsap.set(newSlideImage, {
                        width: '',
                        right: '',
                        left: ''
                    });

                    gsap.set(newSlideContent, {
                        width: '',
                        left: ''
                    });

                    gsap.set(newSlideElements, {
                        opacity: '',
                        transform: ''
                    });

                    gsap.set(activeSlideImage, {
                        left: ''
                    });

                    newSlide.addClass('is-active').removeClass('is-new');
                    activeSlide.removeClass('is-active');
                    slider.find('.slider-pagination').trigger('check');
                    slider.data('wait', false);
                }
            }, 0);

            tl_newslide.from(newSlideElements_btn, {
                opacity: 0,
                x: 180,
                duration: 1.4,
                ease: "power4.inOut",
            }, "-=1.4");

            tl_newslide.to(newSlideElements, {
                opacity: 1,
                ease: "expo.out",
                force3D: true,
                duration: 0.8,
                stagger: 0,
                onStart: function () {
                    if (!/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)) {
                        $(newSlideElements_text).each(function (i, el) {
                            let char = $(el).find(".char");
                            gsap.from(char, {
                                opacity: 0,
                                x: 180,
                                ease: "power4.inOut",
                                duration: 1.4,
                                stagger: 0.02
                            }, 0);
                        });
                    } else {
                        gsap.from(newSlideElements_text, {
                            opacity: 0,
                            x: 180,
                            ease: "power4.inOut",
                            duration: 1.4,
                            stagger: 0
                        }, 0);
                    }
                },
            }, "-=1.5");
        }

        function sliderNext(slider, previous) {
            var slides = slider.find('.slider-list-item');
            var activeSlide = slides.filter('.is-active');
            var newSlide = null;
            if (previous) {
                newSlide = activeSlide.prev('.slider-list-item');
                if (newSlide.length === 0) {
                    newSlide = slides.last();
                }
            } else {
                newSlide = activeSlide.next('.slider-list-item');
                if (newSlide.length == 0)
                    newSlide = slides.filter('.slider-list-item').first();
            }

            sliderSwitch(slider, newSlide.index());
        }

        function event() {
            $('.slider-list-item').addClass('is-loaded');

            $('#slider .slider-arrows .slider-arrows-arrow').on('click', function () {
                sliderNext($(this).closest('#slider'), $(this).hasClass('prev'));
            });

            $('#slider .slider-pagination .slider-pagination-item').on('click', function () {
                sliderSwitch($(this).closest('#slider'), $(this).index());
            });

            $('#slider .slider-pagination').on('check', function () {
                var slider = $(this).closest('#slider');
                var pages = $(this).find('.slider-pagination-item');
                var index = slider.find('.slider-list .is-active').index();
                pages.removeClass('is-active');
                pages.eq(index).addClass('is-active');
            });

            if (!/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)) {
                gsap.to("picture.slider-list-item-image", {
                    scale: 1.1,
                    duration: 8,
                    ease: "power1.inOut",
                    repeat: -1,
                    yoyo: true,
                });
            }

        }

        event();
    } catch (error) {}

}