import gsap from "gsap";
import CustomEase from "gsap/CustomEase";
gsap.registerPlugin(CustomEase);
import SplitText from "../plugin/SplitText.min";

const global_duration = 1.4;

export const ease_inoutfast = CustomEase.create("custom", "M0,0 C0.42,0 0,1 1,1");

const configAnimation = {
    global_duration: global_duration,
    global_stagger: 0.15,
    global_trigger: "15% bottom",
    global_offset: `-=${global_duration - 0.4}`,
    global_offset_duration: `-=${global_duration}`,
    global_ease: "power4.out",
    global_scroller: ".smooth-scroll",
}

// From
export const anim_revealBottom = {
    yPercent: 100,
    stagger: configAnimation.global_stagger,
    ease: "expo.out"
}

// From
export const anim_fadeBottom = {
    yPercent: 20,
    opacity: 0,
    stagger: configAnimation.global_stagger - 0.05
}

// From
export const anim_scaleUp = {
    scale: 0,
}

// From
export const anim_perchar = {
    yPercent: 100,
    stagger: 0.06,
    duration: configAnimation.global_duration - 0.4
}

export const lottie_config = {
    renderer: 'svg',
    loop: true,
    autoplay: false,
}

export const cursor_active = {
    width: "90px",
    height: "90px",
    background: "#FFFFFF",
    duration: configAnimation.global_duration - 0.8,
}

export const cursor_inactive = {
    width: "45px",
    height: "45px",
    background: "transparent",
    duration: configAnimation.global_duration - 0.8,
}

// Utility helper
export function Utility() {

    // Split per line
    gsap.utils.toArray(".split-line").forEach((el, i) => {
        new SplitText(el, {
            type: "lines",
            linesClass: "line",
        });
    });

    // Split per line (adding masking)
    gsap.utils.toArray(".reveal-text").forEach((el, i) => {
        new SplitText(el, {
            type: "lines",
            linesClass: "line",
        });

        let wrapper = document.createElement('div');
        wrapper.classList.add("masking-text");
        el.parentNode.insertBefore(wrapper, el);

        wrapper.appendChild(el);
    });

    // Split per word, char
    gsap.utils.toArray(".split-text").forEach((el, i) => {
        new SplitText(el, {
            type: "lines, words, chars",
            linesClass: "line line++",
            charsClass: "char",
            wordsClass: "word",
        });
    });
}

export default configAnimation;
