import $ from "jquery";
import gsap from "gsap";
import {
    locoScroll
} from './locomotive';
import Swiper, {
    Navigation
} from 'swiper';
Swiper.use([Navigation]);

export default function WorkDetail() {
    function bgZoom() {
        gsap.to(".work-nodetail-bg img, .work-detail-bg img", {
            scale: 1.1,
            duration: 8,
            ease: "power1.inOut",
            repeat: -1,
            yoyo: true,
        });
    }

    function parallax() {
        gsap.utils.toArray(".parallax .parallax-image").forEach((section, i) => {
            gsap.set(section, {
                yPercent: -30
            });
            gsap.to(section, {
                scrollTrigger: {
                    onUpdate: (self) => {
                        gsap.set(section, {
                            yPercent: self.progress * 50 - 30
                        });
                    },
                    trigger: section,
                    start: "top bottom",
                    end: "200% center",
                    scrub: true
                }
            });
        });
    }

    function slider() {
        const swiper = new Swiper('.swiper-container', {
            speed: 700,
            loop: true,
            grabCursor: true,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
        });
    }

    function related() {
        $(".work-detail-related-item-2").on("mouseenter", function () {
            $(".work-detail-related-image-img-2").addClass("active");
        });
    
        $(".work-detail-related-item-2").on("mouseleave", function () {
            $(".work-detail-related-image-img-2").removeClass("active");
        });
    
        $(".backtotop").on("click", function (e) {
            locoScroll.scrollTo(0);
        });
    }

    try {
        bgZoom();
    } catch (error) {
        
    }
    
    try {
        slider();
    } catch (error) {
    }

    try {
        parallax();
    } catch (error) {
        
    }

    try {
        related();
    } catch (error) {
        
    }

}