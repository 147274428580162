import $ from "jquery";
import imagesLoaded from "imagesloaded";
import barba from '@barba/core';
import gsap from 'gsap';
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
import Locomotive, {
    locoScroll
} from './locomotive';
import configAnimation from "./config/animation";
import {
    _forEachName
} from 'gsap/gsap-core';

function delay(n) {
    n = n || 2000;
    return new Promise(done => {
        setTimeout(() => {
            done()
        }, n);
    });
}

function pageTransition(_callback) {
    const isblackel = $("body, a, .slider-pagination-item, .slider-arrows-arrow, .footer-email-link-icon, .footer-socmed-list-item, .digitalagency-mobile");
    const isblackel_bg = $(".link-border, .hamburger-line");

    gsap.to(isblackel, {
        color: "#FFFFFF",
        borderColor: "#FFFFFF",
        stagger: 0,
    });

    gsap.to(isblackel_bg, {
        background: "#FFFFFF",
        stagger: 0,
    }, 0);

    gsap.to('.logo-white', {
        opacity: 1,
    }, 0);

    gsap.to('.logo-black', {
        opacity: 0,
    }, 0);

    const tl_transition = gsap.timeline();

    tl_transition.set(".loading-overlay", {
        width: "100%",
        scaleX: 1,
        transformOrigin: "right",
    });

    tl_transition.to("#loading", {
        scaleX: 1,
        transformOrigin: "right",
        ease: "expo.inOut",
        duration: 1.4,
        display: "block",
    });

    tl_transition.to(".loading-overlay", {
        scaleX: 0.1,
        xPercent: -100,
        ease: "expo.inOut",
        duration: 1.4,
    }, "-=1.4");

    tl_transition.set(".loading-logo", {
        display: "block",
        opacity: 1,
    }, 0);

    tl_transition.from(".loading-logo-item", {
        scale: 1.3,
        opacity: 0,
        x: 20,
        ease: "expo.inOut",
        duration: 1.4,
        stagger: 0.05,
    }, "-=1.1");

    tl_transition.to(".loading-logo", {
        opacity: 0,
        duration: 0.8,
        stagger: 0,
    }, "-=0.5");

    tl_transition.to("#loading", {
        scaleX: 0,
        transformOrigin: "left",
        ease: "expo.inOut",
        duration: 1.4,
        display: "none",
        onStart: _callback
    }, "-=0.3");

    tl_transition.set(".loading-overlay", {
        scaleX: 1,
        xPercent: 100,
    }, "-=1.4");

    tl_transition.to(".loading-overlay", {
        scaleX: 0.1,
        xPercent: -100,
        transformOrigin: "right",
        ease: "expo.inOut",
        duration: 1.4,
    }, "-=1.4");
}

function page_agency() {
    gsap.set(".global-video", {
        display: "block",
    });
    gsap.set("#navbar", {
        css: {
            background: "linear-gradient(180deg, rgb(19, 22, 31), rgb(19, 22, 31), rgba(19, 22, 31, 0.3), transparent)",
            paddingBottom: "10em"
        }
    });
}

function page_contact() {
    gsap.set(".global-video", {
        display: "block",
    });
    gsap.set("#navbar", {
        css: {
            background: "transparent",
            paddingBottom: "0"
        }
    });
}

function page_work() {
    gsap.set(".global-video", {
        display: "none",
    });
    gsap.set("#navbar", {
        css: {
            background: "transparent",
            paddingBottom: "0"
        }
    });
}

export default function Transitions(_first, _enter) {
    barba.hooks.beforeEnter(() => {
        locoScroll.destroy();
    });

    barba.hooks.after(() => {
        Locomotive();
    });

    barba.init({
        sync: true,
        transitions: [{
                name: 'app-transition',
                leave() {
                    const done = this.async();

                    pageTransition(() => {
                        done();
                    });
                },
                once() {
                    _first();
                },
                enter() {
                    _enter();
                },
            },
            {
                name: 'work-transition',
                from: {
                    namespace: [
                        'work'
                    ]
                },
                once() {
                    _first();
                    page_work();
                },
                enter() {
                    _enter();
                },
                leave() {
                    const done = this.async();
                    
                    function startTransition() {
                        pageTransition(() => {
                            done();
                        })
                    }

                    const tl_workleave = gsap.timeline({
                        onComplete: setTimeout(() => {
                            startTransition();
                        }, 1000)
                    });

                    tl_workleave.set(".work-slider-loader", {
                        opacity: 1,
                    });

                    tl_workleave.to(".carousel", {
                        opacity: 0,
                    });
                    
                    tl_workleave.to(".work-slider-bg-img.active-click", {
                        width: "100vw",
                        ease: "expo.inOut"
                    }, 0);
                }
            },
            {
                name: 'agency-transition',
                to: {
                    namespace: [
                        'agency'
                    ]
                },
                once() {
                    _first();
                    page_agency();
                }
            },
            {
                name: 'contact-transition',
                to: {
                    namespace: [
                        'contact'
                    ]
                },
                once() {
                    _first();
                    page_contact();
                }
            }
        ],
        views: [{
                namespace: 'home',
                beforeEnter() {
                    gsap.set("#navbar", {
                        css: {
                            background: "transparent",
                            paddingBottom: "0"
                        }
                    });
                },
            },
            {
                namespace: 'work',
                beforeEnter() {
                    page_work();
                },
            },
            {
                namespace: 'work-detail',
                afterEnter() {
                    const imgLoad = imagesLoaded("#main", {
                        background: '.img'
                    });

                    imgLoad.on("always", () => {
                        locoScroll.update();
                        console.log("img loaded");
                    });
                },
            },
            {
                namespace: 'agency',
                beforeEnter() {
                    page_agency();
                },
            },
            {
                namespace: 'contact',
                beforeEnter() {
                    page_contact();
                },
            }
        ]
    });
}