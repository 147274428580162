// Dependency
import barba from '@barba/core';
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import CustomEase from "gsap/CustomEase";
gsap.registerPlugin(ScrollTrigger, CustomEase);
import {
    Utility
} from "./config/animation";
import Locomotive, {
    locoScroll
} from './locomotive';

import Default from './config/default';
import Loading from './loading';
import Transitions from './transitions';
import Magnet from './magnet';
import Customcursor from './custom_cursor'
import Slider from './slider';
import Hover from './hover';
import Mobile from './mobile';
import Agency from "./agency";
import Work from './work';
import WorkDetail from './work_detail';

export default function Init() {
    Default();
    Locomotive();

    Loading(() => {
        if (!/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)) {
            setTimeout(() => {
                Customcursor();
            }, 1000);

            Magnet();
            Hover();
        }
        Utility();
        Slider();
        Mobile();
        Work();
        WorkDetail();
        Agency();


        locoScroll.update();
    });
}

function Enter() {
    Utility();

    if (!/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)) {
        setTimeout(() => {
            Customcursor();
        }, 1000);

        Magnet();
    }

    Slider();
    Work();
    WorkDetail();
    Agency();

    locoScroll.update();
}

function First() {
    Init();
}


Transitions(() => {
    First();
}, () => {
    Enter();
})