import gsap from "gsap";

export default function Agency() {
    intro();
    scrolldown();
    sayhi();
}

function intro() {
    gsap.from(".agency-intro-wrap", {
        opacity: 0,
        x: 100,
        ease: "expo.inOut",
        duration: 1.4,
        stagger: 0,
        delay: 0.5,
    });
}

function scrolldown() {
    const tl_scrolldown = gsap.timeline({
        repeat: -1,
        defaults: {
            ease: "expo.inOut"
        }
    });

    tl_scrolldown.set(".agency-intro-desc-scroll-text-2", {
        yPercent: 100,
    }, 0);

    tl_scrolldown.to(".agency-intro-desc-scroll-text", {
        yPercent: -100,
    }, 0);

    tl_scrolldown.to(".agency-intro-desc-scroll-text-2", {
        yPercent: 0,
    }, 0);
}

function sayhi() {
    const tl_sayhi = gsap.timeline({
        repeat: -1,
        repeatDelay: 1,
        defaults: {
            duration: 1.4,
            ease: "expo.inOut",
        }
    });

    tl_sayhi.set(".agency-contact-subtitle-rollover-2, .contact-contact-subtitle-rollover-2", {
        yPercent: 100,
    }, 0);

    tl_sayhi.set(".agency-contact-subtitle-rollover-3, .contact-contact-subtitle-rollover-3", {
        yPercent: 200,
    }, 0);

    tl_sayhi.to(".agency-contact-subtitle-rollover-1, .contact-contact-subtitle-rollover-1", {
        yPercent: -100,
    }, 0);

    tl_sayhi.to(".agency-contact-subtitle-rollover-2, .contact-contact-subtitle-rollover-2", {
        yPercent: 0,
    }, 0);

    tl_sayhi.to(".agency-contact-subtitle-rollover-2, .contact-contact-subtitle-rollover-2", {
        yPercent: -100,
    } , "+=1.4");

    tl_sayhi.to(".agency-contact-subtitle-rollover-3, .contact-contact-subtitle-rollover-3", {
        yPercent: 0,
    }, "-=1.4");

    tl_sayhi.to(".agency-contact-subtitle-rollover-3, .contact-contact-subtitle-rollover-3", {
        yPercent: -100,
    }, "+=1.4");

    tl_sayhi.set(".agency-contact-subtitle-rollover-1, .contact-contact-subtitle-rollover-1", {
        yPercent: 100,
    }, "-=1.4");

    tl_sayhi.to(".agency-contact-subtitle-rollover-1, .contact-contact-subtitle-rollover-1", {
        yPercent: 0,
    }, "-=1.4");
}